.clef {
  font: 64px serif;
}

.container {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr auto;
  align-items: center;
}

.notationContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.noteEllipse {
  transform: rotate(-45deg);
  transform-origin: center;
  transform-box: fill-box;
}

.staffLine {
  stroke: rgba(0, 0, 0, 0.5);
}

.noteSharp {
  font-size: 18px;
}

.button {
  color: white;
  padding: 0;
  background: rgba(0, 0, 0, 0.25);
  height: 36px;
  width: 36px;
  font-size: 16px;
  line-height: 1;
  border: solid transparent 0;
}

.button:hover {
  background: #111111;
  border: solid transparent 0;
}

.button:active {
  background: #999999;
  border: solid transparent 0;
}
