.output {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 18px;
}

.outputTitle {
  margin: 0;
}

.outputHint {
  margin: 0;
  line-height: 1.6;
}

.exercises {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 18px;
}

@media only screen and (min-width: 1100px) {
  .exercises {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    align-items: start;
  }
}

