.content {
  display: grid;
  grid-gap: 18px;
  grid-auto-flow: row;
  align-content: flex-start;
  padding: 16px;
  padding-bottom: 0;
  min-height: 100vh;
}

.button {
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  border: solid transparent 0;
  padding: 8px 0;
  color: white;
  background-color: black;
  cursor: pointer;
}

.button:focus {
  border: solid transparent 0;
  text-decoration: underline;
}

.button:hover {
  background: #111111;
  border: solid transparent 0;
}

.button:active {
  background: #999999;
  border: solid transparent 0;
}

.footer {
  display: grid;
  padding: 8px;
  background: white;
  position: sticky;
  bottom: 0;
}
