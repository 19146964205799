.exercise {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr;
    padding-left: 16px;
    padding-right: 16px;
    border-left: 16px solid black;
    background: #eeeeee;
}

.info {
  line-height: 1.6;
}

@media only screen and (min-width: 1600px) {
  .exercise {
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 16px
  }

  .ipaReader {
      grid-column: 2 span;
  }
}
